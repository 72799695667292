<template>
  <div class="subcategory">
    <b-row class="align-items-center">
      <slot name="icon"></slot>
      <b-col cols="auto">
        <LetterIcon :obj="material" :title="material.title" class="student-icon sm"/>
      </b-col>
      <b-col>
        {{ material.title }}
      </b-col>
      <b-col md="auto" cols="10" class="mt-md-0 mt-3 text-right" v-if="material.topics.length > 0">
        <div class="d-inline-block">
                    <span class="badge-topic ml-2" v-for="(t,tind) in material.topics"
                          :key="'topic-'+material.id+'-'+tind">
                        {{ t.title }}
                    </span>
        </div>
      </b-col>
      <b-col lg="auto" cols="6" v-if="editable && canEdit">
        <div class="text-right">
          <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <MoreSvg class="d-inline-block"/>
            </template>
            <b-dropdown-item @click.stop="deleteSubject" v-if="canEdit">{{ $t('delete') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import LetterIcon from '@/views/parts/general/LetterIcon'
import MoreSvg from '@/assets/svg/more.svg'

export default {
  components: {
    LetterIcon, MoreSvg
  },
  props: {
    material: {},
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    canEdit() {
      let user = this.$store.state.user
      if(!user) return false
      if(user.primary_role_id == 3) {
        return true
      }
      let course = this.material.course
      if(!course) {
        return false
      }
      return course.type == 'custom' && (course.instructor_id == user.id || course.school_id == user.id)
    },
  },
  methods: {
    deleteSubject() {
      this.$emit('remove')
    },
    materialSelection() {
      this.$emit('materialSelection')
    },
    assignTeachers() {
      alert('123')
    }
  }
}
</script>

<style scoped lang="scss">
</style>






