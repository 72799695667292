<template>
  <div>
    <div class="spacecard mb-3"  v-if="currentCourse">
      <b-row class="align-items-center mb-4">
        <b-col cols="auto">
          <!--                    <div class="course-icon lg mb-0" :class="{ mine: currentCourse.type == 'custom' }" :class="'course-type-'+ctype">-->
          <div class="course-icon lg mb-0" :class="'course-type-'+ctype">
            <LetterIcon :obj="currentCourse"
                        :title="currentCourse.title"
                        class="course-icon-circle"/>
          </div>
        </b-col>
        <b-col>
          <div class="position-relative">
            <h2 class="my-0">{{ currentCourse.title }}</h2>
          </div>
        </b-col>
        <b-col cols="auto">
          <a v-if="canBeEdited"
             @click="editCourse"
             class="btn-themed btn-white">
            <PencilSvg class="svg-icon-sm"/>
          </a>
          <a v-if="canAssignCourse"  @click="assignCourse" class="btn-themed btn-white ml-2">
            <AssignSvg class="svg-icon-sm svg-darkgray"/>
          </a>
          <!--                            <b-button @click="destroy" class="btn-themed btn-white"><PencilSvg class="svg-icon-sm"/></b-button>-->
          <!--                            <b-button v-if="changed" class="btn-themed ml-2" @click="save">{{ $t('save') }}</b-button>-->
        </b-col>
      </b-row>
      <div class="search-input-wrapper mb-4">
        <SearchSvg class="position-absolute search-icon" @click="search"/>
        <input class="search-form-control" v-model="q" :placeholder="$t('search')"/>
      </div>
      <div v-if="found.length > 0">
        <div class="subcategory-wrapper pl-0">
          <div v-for="(material, mind) in found" :key="'found-material-'+mind">
            <MaterialTeaser  @materialSelection="materialselected(material)" :material="material"/>
          </div>
        </div>
      </div>
      <div v-if="found.length == 0 && q">
        <div class="alert alert-warning mb-4">{{ $t('nothing_found') }}</div>
      </div>
      <div v-if="found.length == 0 && !q">
        <a v-if="canBeEdited || roleId == 6"
           class="btn-whole-add btn-whole-add-dashed"
           @click="createNewLesson">
                    <span class="svg-wrapper mr-3">
                    <PlusSvg class="svg-icon-sm"/></span>{{ $t('create_new_lesson') }}
        </a>
        <div class="mt-4">
          <div v-for="(level) in levels" :key="'level-'+level.id">
            <template  v-if="currentCourse.materialsKeyed && currentCourse.materialsKeyed[level.id]">
              <AccessoryRow :title="level.code+'. '+level.title" v-b-toggle="'collapse-'+level.id"/>
              <b-collapse :id="'collapse-'+level.id">
                <div class="subcategory-wrapper">
                  <draggable v-model="currentCourse.materialsKeyed[level.id]" @end="draggedMaterial(level.id)">
                    <div v-for="(material, mind) in currentCourse.materialsKeyed[level.id]" :key="'material-'+mind">
                      <MaterialTeaser  v-on:remove="removeSubject(material)" @click.native="materialselected(material)" :material="material">
                        <template #icon>
                          <b-col cols="1">
                            <SlideSvg class="mr-1"/>
                          </b-col>
                        </template>
                      </MaterialTeaser>
                    </div>
                  </draggable>
                </div>
              </b-collapse>
            </template>
          </div>
        </div>
      </div>
    </div>
    <AddMaterialModal v-if="currentCourse" :course="currentCourse"/>
    <AssignCourseModal v-if="currentCourse && canAssignCourse" :course="currentCourse" @reload="reload"/>
    <PresentationPreviewModal v-if="showmodal" :material="previewMaterial" :homework="false"/>
  </div>
</template>

<script>
// import TrashSvg from '@/assets/svg/trash2.svg'
import { MaterialsService } from '@/services/api.service'
import PencilSvg from '@/assets/svg/pen.svg'
import SearchSvg from '@/assets/svg/search.svg'
import SlideSvg from '@/assets/svg/slide.svg'
import PlusSvg from '@/assets/svg/plus.svg'
import AssignSvg from '@/assets/svg/user_group.svg'
import AccessoryRow from '@/views/teasers/AccessoryRow'
import { CoursesService } from '@/services/api.service'
import AddMaterialModal from '@/views/pages/instructor/courses/AddMaterialModal'
import LetterIcon from '@/views/parts/general/LetterIcon'
import MaterialTeaser from '@/views/teasers/MaterialTeaser'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import draggable from 'vuedraggable'
import AssignCourseModal from "../../../parts/class/AssignCourseModal"


export default {
  components: {
    AssignCourseModal,
    AccessoryRow,
    AddMaterialModal,
    MaterialTeaser,
    PencilSvg,
    PlusSvg,
    LetterIcon,
    SearchSvg,
    AssignSvg,
    PresentationPreviewModal,
    draggable,
    SlideSvg
  },
  data() {
    return {
      currentCourse: { title: this.$t('my_new_material') },
      changed: false,
      showmodal: false,
      q: "",
      previewMaterial: null,
      found: [],
    }
  },
  props: {
    course: { type: Object },
    ctype: { type: String },
    levels: {}
  },
  mounted() {
    if(this.course) {
      this.currentCourse = this.course
    }
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    })
  },
  watch: {
    course(val) {
      this.currentCourse = val
      this.changed = false
      this.q = ""
    },
    title() {
      this.changed = true
    },
    id() {
      this.$nextTick(() => { this.changed = false })
    },
    q(val) {
      this.found = []
      if(!val) {
        return
      }
      for (var index in this.currentCourse.materialsKeyed) {
        if (Object.prototype.hasOwnProperty.call(this.currentCourse.materialsKeyed, index)) {
          let d = this.currentCourse.materialsKeyed[index]
          for (var i = 0; i < d.length; i++) {
            let ob = d[i]
            var included = false
            if(ob.title.toLowerCase().includes(val.toLowerCase())) {
              this.found.push(ob)
              included = true
            }
            if(!included) {
              for (var z = 0; z < ob.topics.length; z++) {
                if(!included) {
                  let topic = ob.topics[z]
                  if(topic.title.toLowerCase().includes(val.toLowerCase())) {
                    this.found.push(ob)
                    included = true
                    // return
                  }
                }
              }
            }
          }

        }
      }
    }
  },
  computed: {
    title() {
      return this.currentCourse.title
    },
    id() {
      return this.currentCourse.id
    },
    roleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : null 
    },
    canBeEdited() {
      let user = this.$store.state.user
      if(!user) return false;
      if(user.primary_role_id == 3) {
        return true
      }
      if(this.currentCourse.type == 'custom' && (user.id == this.currentCourse.instructor_id || user.id == this.currentCourse.school_id)) {
        return true
      }
      return false
    },
    canAssignCourse() {
      return [3,4,5,6].includes(this.roleId)
    }
  },
  methods:  {
    add() {
      CoursesService.add({ title: this.currentCourse.title }).then(res => {
        this.currentCourse = res.data.data
        this.$emit('added', this.currentCourse)
      })
    },
    editCourse() {
      this.$parent.addingnew = false
      this.$bvModal.show('addcoursemodal')
    },
    assignCourse() {
      this.$bvModal.show('assigncoursemodal')
    },
    createNewLesson() {
      this.$bvModal.show('addmaterialmodal')
    },
    removeSubject (material) {
      this.loading = true
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        MaterialsService.delete(material.course_id, material.id).then(() => {
          this.$emit('reload')
          this.loading = false
          this.$toast(this, this.$t('material_deleted'))
        })
      });
    },
    reload() {
      this.$emit('reload')
    },
    materialselected(material) {
      let canEdit = false
      let user = this.$store.state.user
      if(user.primary_role_id == 3) {
        canEdit = true
      }
      if(user.primary_role_id == 6) {
        canEdit = true
      }
      if(this.currentCourse.instructor_id == user.id || this.currentCourse.school_id == user.id) {
        canEdit = true
      }
      if(canEdit) {
        let name =  'Material'
        if(this.$store.state.user.primary_role_id == 4) {
          name = 'SchoolMaterial'
        }
        this.$router.push({ name: name, params: {
            cid: this.currentCourse.id,
            mid: material.id
          }
        })
      } else {
        this.previewMaterial = material
        this.showmodal = true
        setTimeout(() => {
          this.$bvModal.show('presentationpreviewmodal')
        }, 50)
      }

    },
    search() {

    },
    draggedMaterial(level_id) {
      let res = this.currentCourse.materialsKeyed[level_id]
      let obj = []
      res.forEach((item, index) => {
        obj.push({
          id: item.id,
          index: index
        })
      })
      CoursesService.resort(this.id, { sorting: obj })
    }
  }
}
</script>

<style scoped lang="scss">

</style>


